import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { UseContext } from "../../../data/infra/context";
import { Masks } from "../../../data/entitys";
import { CarModel, CarSpecModel } from "../../../domain/entitys";
import { LoadCarSpecsLists } from "../../../domain/usecases";
import Select from "../../components/select";
import Input from "../../components/input";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

type Props = {
  loadCarSpecsLists: LoadCarSpecsLists;
  masks: Masks;
};

const initialValues: CarModel = {
  brand: "",
  model: "",
  year: "",
  version: "",
  km: "",
};

const validationSchema = yup.object({
  brand: yup.string().required("Informe a marca"),
  model: yup.string().required("Informe o modelo"),
  year: yup.string().required("Informe o ano"),
  version: yup.string().required("Informe a versão"),
  km: yup.string().required("Campo obrigatório"),
});

const CarForm: React.FC<Props> = ({ loadCarSpecsLists, masks }: Props) => {
  const [state, setState] = UseContext();
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState<CarSpecModel[]>([]);
  const [modelList, setModelList] = useState<CarSpecModel[]>([]);
  const [yearList, setYearList] = useState<CarSpecModel[]>([]);
  const [versionList, setVersionList] = useState<CarSpecModel[]>([]);

  const onSubmit = (values: CarModel) => {
    const car = {
      brand: brandList.filter((brand) => brand.id === Number(values.brand))[0]
        .name,
      model: modelList.filter((model) => model.id === Number(values.model))[0]
        .name,
      year: yearList.filter((year) => year.id === Number(values.year))[0].name,
      version: versionList.filter(
        (version) => version.id === Number(values.version)
      )[0].name,
      km: values.km,
    };
    const carPrice = {
      makeId: brandList.filter((brand) => brand.id === Number(values.brand))[0]
        .web_id,
      modelId: modelList.filter((model) => model.id === Number(values.model))[0]
        .web_id,
      yearModel: yearList.filter((year) => year.id === Number(values.year))[0]
        .web_id,
      versionId: versionList.filter(
        (version) => version.id === Number(values.version)
      )[0].web_id,
      odometer: Number(masks.unMask(values.km)),
    };
    setState({ car, carPrice });
    navigate("/cliente");
  };

  useEffect(() => {
    const loadBrand = async (): Promise<void> => {
      setState({ isLoading: true });
      loadCarSpecsLists
        .loadBrandList()
        .then((brandList) => {
          setState({ isLoading: false });
          setBrandList(brandList);
        })
        .catch((e: Error) => {
          setState({
            isLoading: false,
            isError: true,
            errorMessage: e.message,
          });
        });
    };
    loadBrand();
  }, []);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Header />
            <Row>
              <Col xs={12}>
                <Select
                  className="mb-3"
                  name="brand"
                  value={values.brand}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    handleChange(e);
                    setState({ isLoading: true });
                    loadCarSpecsLists
                      .loadModelList(e.target.value)
                      .then((brandList) => {
                        setState({ isLoading: false });
                        setModelList(brandList);
                      })
                      .catch((e: Error) => {
                        setState({
                          isLoading: false,
                          isError: true,
                          errorMessage: e.message,
                        });
                      });
                  }}
                  options={brandList}
                  placeholder="Selecione a marca"
                  label="Marca"
                  isValid={touched.brand && !errors.brand}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Select
                  className="mb-3"
                  name="model"
                  value={values.model}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    handleChange(e);
                    setState({ isLoading: true });
                    loadCarSpecsLists
                      .loadYearList(e.target.value)
                      .then((yearList) => {
                        setState({ isLoading: false });
                        setYearList(yearList);
                      })
                      .catch((e: Error) => {
                        setState({
                          isLoading: false,
                          isError: true,
                          errorMessage: e.message,
                        });
                      });
                  }}
                  options={modelList}
                  placeholder="Selecione o modelo"
                  label="Modelo"
                  disabled={values.brand.length === 0}
                  isValid={touched.model && !errors.model}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Select
                  className="mb-3"
                  name="year"
                  value={values.year}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    handleChange(e);
                    setState({ isLoading: true });
                    loadCarSpecsLists
                      .loadVersionList(e.target.value)
                      .then((versionList) => {
                        setState({ isLoading: false });
                        setVersionList(versionList);
                      })
                      .catch((e: Error) => {
                        setState({
                          isLoading: false,
                          isError: true,
                          errorMessage: e.message,
                        });
                      });
                  }}
                  options={yearList}
                  placeholder="Selecione o ano"
                  label="Ano"
                  disabled={values.model.length === 0}
                  isValid={touched.year && !errors.year}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Select
                  className="mb-3"
                  name="version"
                  value={values.version}
                  onChange={handleChange}
                  options={versionList}
                  placeholder="Selecione a versão"
                  label="Versão"
                  disabled={values.year.length === 0}
                  isValid={touched.version && !errors.version}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Input
                  className="mb-3"
                  name="km"
                  value={values.km}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const copyE = e;
                    copyE.target.value = masks.decimal(e.target.value);
                    handleChange(copyE);
                  }}
                  placeholder="Quilometragem"
                  label="Quilometragem"
                  isValid={touched.km && !errors.km}
                />
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <Button variant="primary" type="submit">
                  Quero uma cotação
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {/* <Footer></Footer> */}
    </Container>
  );
};

export default CarForm;
