import { CarSpecModel } from "../../domain/entitys";
import { AccessDeniedError, UnexpectedError } from "../../domain/errors";
import { LoadCarSpecsLists } from "../../domain/usecases";
import {
  HttpClient,
  HttpResponse,
  HttpStatusCode,
} from "../entitys/http-client";

export class RemoteLoadCarSpecsLists implements LoadCarSpecsLists {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<CarSpecModel[]>
  ) {}

  handleResponse(response: HttpResponse<CarSpecModel[]>): CarSpecModel[] {
    switch (response.statusCode) {
      case 200:
        if (Array.isArray(response.body) && response.body.length) {
          return response.body;
        }
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }

  async loadBrandList(): Promise<CarSpecModel[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/brands`,
      method: "get",
    });
    return this.handleResponse(httpResponse);
  }

  async loadModelList(brandId: string): Promise<CarSpecModel[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/brands/${brandId}/models`,
      method: "get",
    });
    return this.handleResponse(httpResponse);
  }

  async loadYearList(modelId: string): Promise<CarSpecModel[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/models/${modelId}/year`,
      method: "get",
    });
    return this.handleResponse(httpResponse);
  }

  async loadVersionList(yearId: string): Promise<CarSpecModel[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/year/${yearId}/version`,
      method: "get",
    });
    return this.handleResponse(httpResponse);
  }
}
