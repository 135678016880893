import styled from "styled-components";
import theme from "../styles/theme";

export const Container = styled.main`
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;

  .error-alert {
    z-index: 3;
  }

  .container-footer {
    position: relative;
    margin-top: 1rem;
  }

  .footer {
    z-index: 2;
    background-color: ${theme.colors.tertiary};
    padding: 1rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: ${theme.colors.primary};
    text-align: center;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url("/bg.jpg");
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
  background-position: center center;
  background-size: cover;
`;

interface LoadingOverlayProps {
  show?: boolean;
}

export const LoadingOverlay = styled.div<LoadingOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0.5;
  display: ${(props) => (props.show ? "inline-grid" : "none")};
  background: ${theme.colors.text};
  background-position: center center;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  max-width: 30rem;
  padding: 2rem 1rem;

  form {
    border-radius: 20px;
    padding: 45px 30px;
    background: ${theme.colors.tertiary}aa;
    // height: 48rem;
  }

  ::selection {
    color: ${theme.colors.primary};
    background: ${theme.colors.secondary};
  }

  ::-moz-selection {
    color: ${theme.colors.primary};
    background: ${theme.colors.secondary};
  }

  .btn-primary {
    background-color: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
    color: ${theme.colors.primary};
  }

  .form-select:focus {
    border-color: ${theme.colors.secondary};
    outline: 0;
    box-shadow: 0 0 0 0.25rem ${theme.colors.secondary}32;
  }

  .form-check-input:checked {
    background-color: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23333233' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  }

  option:checked,
  option:focus,
  option:valid {
    background-color: ${theme.colors.secondary};
  }
`;

export const CenterLoading = styled.div`
  min-height: 50%;
  min-height: 50vh;

  display: flex;
  align-items: center;
`;
