import React, { InputHTMLAttributes } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { CarSpecModel } from "../../domain/entitys";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  placeholder: string;
  label: string;
  disabled?: boolean;
  isValid?: boolean;
}

const Input: React.FC<Props> = ({
  name,
  value,
  placeholder,
  label,
  disabled,
  isValid,
  ...rest
}: Props) => {
  return (
    <Form.Group controlId={name}>
      <FloatingLabel label={label}>
        <Form.Control
          type="text"
          name={name}
          placeholder={placeholder}
          onChange={rest.onChange}
          className={rest.className}
          disabled={disabled}
          isValid={isValid}
          required
        />
      </FloatingLabel>
    </Form.Group>
  );
};

export default Input;
