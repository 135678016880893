import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { UseContext } from "../../data/infra/context";
import ErrorAlert from "../components/error";
import Footer from "../components/footer";
import WhatsappBtn from "../components/whatsapp";
import {
  Container,
  Content,
  Background,
  LoadingOverlay,
  CenterLoading,
} from "./styles";

const DefaultLayout: React.FC = ({ children }) => {
  const [state] = UseContext();
  return (
    <Container>
      <ErrorAlert />
      <LoadingOverlay show={state.isLoading}>
        <Row className="justify-content-center mt-3">
          <Col xs="auto">
            <CenterLoading>
              <Spinner animation="border" />
            </CenterLoading>
          </Col>
        </Row>
      </LoadingOverlay>
      <Content>{children}</Content>
      <Row className="justify-content-center mt-3">
        <Col xs="12">
          <Footer />
        </Col>
      </Row>
      <Background />
      <WhatsappBtn />
    </Container>
  );
};

export default DefaultLayout;
