import React, { ChangeEvent } from "react";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { UseContext } from "../../../data/infra/context";
import { Masks } from "../../../data/entitys";
import { ClientModel } from "../../../domain/entitys";
import Input from "../../components/input";
import Header from "../../components/header";
import { CreateLead } from "../../../domain/usecases";
import { useNavigate } from "react-router-dom";

type Props = {
  masks: Masks;
  createLead: CreateLead;
};

const initialValues: ClientModel = {
  name: "",
  phone: "",
};

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  phone: yup.string().required("Campo obrigatório"),
});

const ClientForm: React.FC<Props> = ({ masks, createLead }: Props) => {
  const [state, setState] = UseContext();
  const navigate = useNavigate();

  const onSubmit = (values: ClientModel) => {
    setState({ isLoading: true });
    createLead
      .create(values, state.car)
      .then((lead) => {
        setState({ isLoading: false, client: values });
        navigate("/precificador");
      })
      .catch((e: Error) => {
        setState({
          isLoading: false,
          isError: true,
          errorMessage: e.message,
        });
      });
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Header />
            <Row>
              <Col xs={12}>
                <Input
                  className="mb-3"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Nome"
                  label="Nome"
                  isValid={touched.name && !errors.name}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Input
                  className="mb-3"
                  name="phone"
                  value={values.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const copyE = e;
                    copyE.target.value = masks.phone(e.target.value);
                    handleChange(copyE);
                  }}
                  placeholder="Telefone"
                  label="Telefone"
                  isValid={touched.phone && !errors.phone}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Check
                  required
                  type="checkbox"
                  label="Eu concordo em receber comunicações da 4Boss"
                />
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <Button variant="primary" type="submit">
                  Quero uma cotação
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ClientForm;
