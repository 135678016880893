const theme = {
  colors: {
    bgSecondary: "#F0F5F2",
    primary: "#333233",
    secondary: "#fdb913",
    tertiary: "#F0F5F2",
    text: "#fff",
    warn: "#fec300",
    danger: "#f65e5e",
    success: "#008940",
    textSecondary: "#424242",
  },
};

export default theme;
