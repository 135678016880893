import React, { useEffect, useState } from "react";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import { Formik } from "formik";
import { UseContext } from "../../../data/infra/context";
import { Masks } from "../../../data/entitys";
import Header from "../../components/header";
import { CreateLead, Price } from "../../../domain/usecases";
import { useNavigate } from "react-router-dom";

type Props = {
  masks: Masks;
  price: Price;
  createLead: CreateLead;
};

const initialValues: { value: string } = {
  value: "",
};

const PricePage: React.FC<Props> = ({ masks, price, createLead }: Props) => {
  const [state, setState] = UseContext();
  const [value, setValue] = useState<string>();
  const navigate = useNavigate();

  const onSubmit = () => {
    setState({ isLoading: true });
    createLead
      .create(state.client, state.car, value)
      .then(() => {
        setState({ isLoading: false });
        navigate("/fim");
      })
      .catch((e: Error) => {
        setState({
          isLoading: false,
          isError: true,
          errorMessage: e.message,
        });
      });
  };

  useEffect(() => {
    const loadPrice = async (): Promise<void> => {
      setState({ isLoading: true });
      price
        .evaluate(state.carPrice)
        .then((response) => {
          setState({ isLoading: false });
          setValue(
            masks.monetary(
              response.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            )
          );
        })
        .catch((e: Error) => {
          setState({
            isLoading: false,
            isError: true,
            errorMessage: e.message,
          });
        });
    };
    loadPrice();
  }, []);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Header price />

            <Row
              className="justify-content-center mt-3"
              style={{ fontSize: "xxx-large" }}
            >
              <Col xs="auto">
                <h1>R$ {value}</h1>
              </Col>
            </Row>

            <Row
              className="justify-content-center mt-3"
              style={{ fontSize: "xx-small" }}
            >
              <Col xs="auto">
                <p>
                  *Os valores acima são estimativas de mercado e podem sofrer
                  ajustes conforme o estado de conservação e dos itens opcionais
                  do carro. Valores sujeitos à confirmação através de avaliação
                  presencial ou laudo cautelar emitido preferencialmente pela
                  Dekra ou outra empresa de vistoria automotiva. Verifique com
                  um dos nossos operadores as empresas aceitas.
                </p>
                <p>
                  **Se seu carro for vendido, você recebe o valor em até 1 dia
                  útil, contado a partir da entrega da documentação necessária
                  completa.
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <Button variant="primary" onClick={() => onSubmit()}>
                  Estou interessado
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PricePage;
