import React, { SelectHTMLAttributes } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { CarSpecModel } from "../../domain/entitys";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  value: string;
  options: CarSpecModel[] | undefined;
  placeholder: string;
  label: string;
  disabled?: boolean;
  isValid?: boolean;
}

const Select: React.FC<Props> = ({
  name,
  value,
  options,
  placeholder,
  label,
  disabled,
  isValid,
  ...rest
}: Props) => {
  return (
    <Form.Group controlId={name}>
      <FloatingLabel label={label}>
        <Form.Select
          name={name}
          value={value}
          onChange={rest.onChange}
          className={rest.className}
          disabled={disabled}
          isValid={isValid}
          required
        >
          <option disabled={value.length !== 0}>{placeholder}</option>
          {options?.map((option) => (
            <option key={option.name} value={option.id}>
              {option.name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export default Select;
