import React from "react";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import { Formik } from "formik";
import Header from "../../components/header";

const initialValues: { value: string } = {
  value: "",
};

const EndPage: React.FC = () => {
  const onSubmit = () => {
    window?.open("https://www.4boss.com.br/", "_blank")?.focus();
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Header />

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <h6>
                  Obrigado pelo contato! <br /> Nosso time já vai entrar em
                  contato com você
                </h6>
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <h6>
                  Qualquer dúvida, pode nos chamar aqui no WhatsApp ou aproveite
                  para dar uma olhada no nosso estoque.
                </h6>
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col xs="auto">
                <Button variant="primary" type="submit">
                  Ver estoque 4Boss
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EndPage;
