import { CarPriceModel, PriceModel } from "../../domain/entitys";
import { AccessDeniedError, UnexpectedError } from "../../domain/errors";
import { Price } from "../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../entitys/http-client";

export class RemotePrice implements Price {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<PriceModel>
  ) {}

  async evaluate(car: CarPriceModel): Promise<PriceModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: car,
    });
    const response = httpResponse.body || undefined;
    switch (httpResponse.statusCode) {
      case 200:
        if (response?.value) return response;
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}
