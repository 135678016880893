import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { UseContext } from "../../data/infra/context";

const ErrorAlert: React.FC = () => {
  const [state, setState] = UseContext();

  if (state.isError) {
    return (
      <Alert
        variant="danger"
        onClose={() => setState({ isError: false })}
        dismissible
        className="error-alert"
      >
        <Alert.Heading>Erro!</Alert.Heading>
        <p>Desculpe o transtorno, um erro aconteceu: {state.errorMessage}</p>
      </Alert>
    );
  }
  return <></>;
};

export default ErrorAlert;
