import { CarModel, ClientModel, LeadModel } from "../../domain/entitys";
import { LeadPayloadModel } from "../../domain/entitys/lead-payload.model";
import { AccessDeniedError, UnexpectedError } from "../../domain/errors";
import { CreateLead } from "../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../entitys/http-client";

export class RemoteCreateLead implements CreateLead {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LeadModel>
  ) {}

  async create(
    client: ClientModel,
    car: CarModel,
    value?: string
  ): Promise<LeadModel> {
    const payLoad: LeadPayloadModel = {
      utmSource: "precificador 4boss",
      provider: "precificador 4boss/",
      client: {
        firstname: client.name.split(" ")[0],
        lastname: client.name.split(" ")[1],
        phone: client.phone,
      },
      car: {
        marca: car.brand,
        modelo: car.model,
        ano_fabricacao: parseInt(car.year),
        ano_modelo: parseInt(car.year),
        versao: car.version,
        quilometragem: parseInt(car.km),
        valor_anuncio: 0
      },
    };
    if (value) {
      payLoad.car.valor_anuncio = parseInt(value.replace(".", ""));
    }
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: "post",
      body: payLoad,
    });
    const remoteLead = httpResponse.body || undefined;
    switch (httpResponse.statusCode) {
      case 200:
        if (remoteLead?.newAssociate?.id) {
          return remoteLead;
        }
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}
