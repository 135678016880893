import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { MaskValues } from "../data/utils/masks";
import { ContextProvider } from "../data/infra/context";
import {
  makeRemoteCreateLead,
  makeRemoteLoadCarSpecsLists,
} from "../data/factories/usecases";
import { CarForm } from "../presentation/pages";
import DefaultLayout from "../presentation/layouts/default";
import ClientForm from "../presentation/pages/client-form/client-form";
import PricePage from "../presentation/pages/price/price";
import { makeRemotePrice } from "../data/factories/usecases/remote-price-factory";
import EndPage from "../presentation/pages/end/end";

const Router: React.FC = () => {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <DefaultLayout>
                <CarForm
                  loadCarSpecsLists={makeRemoteLoadCarSpecsLists()}
                  masks={new MaskValues()}
                />
              </DefaultLayout>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/cliente"
            element={
              <DefaultLayout>
                <ClientForm
                  masks={new MaskValues()}
                  createLead={makeRemoteCreateLead()}
                />
              </DefaultLayout>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/precificador"
            element={
              <DefaultLayout>
                <PricePage
                  masks={new MaskValues()}
                  price={makeRemotePrice()}
                  createLead={makeRemoteCreateLead()}
                />
              </DefaultLayout>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/fim"
            element={
              <DefaultLayout>
                <EndPage />
              </DefaultLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default Router;
