import { Masks } from "../entitys";

export class MaskValues implements Masks {
  decimal(value: string): string {
    let v = value;
    if (v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/(\d)(\d{6})$/, "$1.$2");
      v = v.replace(/(\d)(\d{3})$/, "$1.$2");
    }
    return v;
  }

  monetary(value: string): string {
    let newValue = value;
    if (newValue) {
      newValue = newValue.replace(/\D/g, "");
      newValue = newValue.replace(/(\d)(\d{8})$/, "$1.$2");
      newValue = newValue.replace(/(\d)(\d{5})$/, "$1.$2");
      newValue = newValue.replace(/(\d)(\d{2})$/, "$1,$2");
    }
    return newValue;
  }

  phone(value: string): string {
    let v = value;
    if (v) {
      return v
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "($1) $2") // coloca () entre os dois primeiro caracteres
        .replace(/(\d{5})(\d)/, "$1-$2") // coloca - apos o 5 digito
        .replace(/(-\d{4})\d+?$/, "$1"); // captura 4 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    return v;
  }

  unMask(value: string): string {
    let v = value;
    if (v) {
      return v
        .replace(/\./g, "") // remove todos os .
        .replace(/-/g, "") // remove todos os -
        .replace(/\(/g, "") // remove todos os (
        .replace(/\)/g, "") // remove todos os )
        .replace(/\//g, "") // remove todos os /
        .replace(/\s/g, ""); // remove todos os " "
    }
    return v;
  }
}
