import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../../assets/logo.png";

type Props = {
  price?: boolean;
};

const Header: React.FC<Props> = ({ price }) => (
  <>
    <Row className="justify-content-center mb-3">
      <a href="https://4boss.com.br" target={'_blank'} style={{textAlign: 'center'}}>
        <img src={logo} alt="4boss logo" style={{width: '80%'}} />
      </a>
      <Col xs="auto" className="mt-0" style={{textAlign: 'center'}}>
        {price ? (
          <h3 style={{paddingTop: 30}}>Avaliamos seu carro em</h3>
        ) : (
          <h3 style={{paddingTop: 30}}>Compramos o seu carro. <br/>Saiba agora quanto ele vale!</h3>
        )}
      </Col>
    </Row>
  </>
);

export default Header;
