import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/logo.png";

const Footer: React.FC = () => (
  <Container fluid className="container-footer">
    <Row className="justify-content-center footer">
      <Col xs="auto" className="mb-0" style={{ fontSize: "12px" }}>
        <span>Telefone: (61) 3550-5472</span>
      </Col>
      <Col xs="auto" className="mb-0" style={{ fontSize: "12px" }}>
        <span>E-mail: vendas@4boss.com.br</span>
      </Col>
      <Col xs="auto" className="mb-0" style={{ fontSize: "12px" }}>
        <span>
          Edifício Mídia, Aeroporto Internacional de Brasília Juscelino
          Kubitscheck - Lago Sul, Brasília - DF, 71608-970
        </span>
      </Col>
    </Row>
  </Container>
);

export default Footer;
