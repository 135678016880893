import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

const WhatsappBtn: React.FC = () => {
  return (
    <FloatingWhatsApp
      showPopup={false}
      message={"message"}
      autoOpenTimeout={99999999}
      popupMessage="Envie-nos uma mensagem"
      size="50px"
      zIndex={10}
      phone="556183500050"
      headerTitle="4Boss"
    />
  );
};

export default WhatsappBtn;
